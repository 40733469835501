











































































import { Vue, Component, Prop } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import VagaService from "@/core/Services/Vaga/Vaga";
import CandidaturaService from "@/core/Services/Candidato/Candidatura";
import CandidatoService from "@/core/Services/Candidato/Candidato";
import CardJobDetails from '@/components/cards/CardJobDetails.vue';
import CardJobCompany from '@/components/cards/CardJobCompany.vue';
import ModalPerguntasCorte from '@/components/modal/ModalPerguntasCorte.vue';
import ModalPhoneInput from '@/components/modal/ModalPhoneInput.vue';
import ModalCurriculoOculto from '@/components/modal/ModalCurriculoOculto.vue';
import JobStep from './partials/JobStep.vue';
import Alert from "@/components/alerts/Alert.vue";
import ModalDiversidade from '@/components/modal/ModalDiversidade.vue';
import { VagaEntity, CandidaturaEntity, CandidatoEntity } from '@/core/models/Entities';

const namespace: string = 'profile';

@Component({
  components: {
    CardJobDetails,
    CardJobCompany,
    'pergunta-corte': ModalPerguntasCorte,
    'modal-phone': ModalPhoneInput,
    JobStep,
    'modal-diversidade' : ModalDiversidade,
    ModalCurriculoOculto
  }
})
export default class Vagas extends Vue {
  @Action('loadCandidatoInfo', { namespace })
  public loadCandidatoInfo: any;

  @Action('fetchCandidatoInfo', { namespace })
  public fetchCandidatoInfo: any;

  @Getter('candidatoInfo', { namespace })
  public candidato?: CandidatoEntity;

  @Prop({ default: false })
  private isPublic!: boolean;
  
  private vagaService: VagaService = new VagaService();
  private candidaturaService: CandidaturaService = new CandidaturaService();
  private candidatoService: CandidatoService = new CandidatoService();
  private vaga: VagaEntity|null = null;
  private candidatura: CandidaturaEntity|null = null;
  private invite: Boolean = false;
  private applyPromise: Promise<any>|null = null;
  public currentPromise: any|null = null;
  public alert: Alert = new Alert();

  // constructor() {
  //   super();
  //   this.vaga = new VagaEntity;
  // }
  
  public async mounted() {
    if (!this.candidato) {
      await this.fetchCandidatoInfo();
    } 
    if(!isNaN(+this.$route.params.id) && this.$route.params.id.length < 8) {
      await this.vagaService.fetch(+this.$route.params.id).then((data: VagaEntity) => {
        this.vaga = data
      });
    } else {
      let data: any = { id: 0 };
      if(this.$route.params.id){
        data["urlCode"] = this.$route.params.id;
      }
      await this.vagaService.fetchWithData(data).then((data: VagaEntity) => {
        this.vaga = data
      });
    }

    if(this.vaga && this.$refs.mpc)  
      (this.$refs.mpc as ModalPerguntasCorte).loadPerguntasCorte(); 


    if (this.vaga?.isCandidatado) {
      const candidatura: CandidaturaEntity = await this.candidaturaService.fetchAll({ idvaga: this.vaga!.id });

      if (candidatura && candidatura.id) {
        this.candidatura = candidatura
        this.candidatura.canAnswer = this.candidatura.canAnswer && this.candidatura.status !== "EXAMESREPROVADO";
      }
    }

    if(this.candidato && this.candidato.perfil.grupoMovBlack == null && this.vaga?.empresa?.configuracoes?.HIDE_DIVERSITY_MODAL !== true){
      
      console.log('start', (new Date()).getTime())
        this.$set(this.candidatoService,'entidade', JSON.parse(JSON.stringify(this.candidato)));
      console.log('end', (new Date()).getTime())
      this.$bvModal.show('modalDiversidade');
    }

    this.$nextTick(() => {
      const dc: boolean = (this.candidatura == null || this.candidatura.status == 'EMREGISTRO');
      const dv: boolean = (this.candidatoService.entidade.perfil.grupoMovBlack == null);
      if(this.$route.query && this.$route.query.a && this.$route.query.a == "1" && dc && !dv){
        (this.$refs.jobDetails as CardJobDetails).applyJob();
      }
    })
  }

  public saveModelDiversidade(){
    this.currentPromise = this.candidatoService.save().then(async(data: any) => {
          if(!this.$route.query.vaid) {
            this.alert.title = this.$t('Success');
            this.alert.addMsg(this.$t('Profile successfully saved'));
            this.alert.callBoxSuccess();            
            this.$bvModal.hide('modalDiversidade');
          }
    })
  }

  get currentLocal() {
    return location.href
  }

  get status() {
    return this.candidatura?.status
  }

  get canAnswer() {
    return this.candidatura?.canAnswer
  }

  get showCompartilhar(): boolean {
    return !!this.vaga && this.vaga.restrita === 'SIM';
  }

  get showEmpresa(): boolean {
    return !!this.vaga && this.vaga.confidencial !== 'SIM';
  }

  get vagaCols(): number {
    return this.showEmpresa ? 9 : 12;
  }

  get applicable() :boolean {
    return this.vaga!.status === 'ATIVO';
  }
  public goDirectToInterview() {
    if(this.candidatura) {
      this.$router.push({
        name: 'Entrevista',
        params: { id: this.candidatura!.id!.toString() },
        query: { lang: this.$i18n.locale }
      }).catch(() => {});
    } else {
      this.apply()
    }
  }

  public goToInterview() {
    // this.$router.push({
    //   name: 'CandidaturaSucesso',
    //   query: { lang: this.$i18n.locale, cid: this.candidatura!.id!.toString()  }
    // });
    this.$router.push({
      name: 'Entrevista',
      params: { id: this.candidatura!.id!.toString() },
      query: { lang: this.$i18n.locale }
    }).catch(() => {});
    this.$bvModal.hide('modal-job-step')
  }
  public goToExam() {
    // this.$router.push({
    //   name: 'CandidaturaSucesso',
    //   query: { lang: this.$i18n.locale, cid: this.candidatura!.id!.toString()  }
    // });
    this.$router.push({
      name: 'Exams',
      params: { cid: this.candidatura!.id!.toString() },
      query: { lang: this.$i18n.locale }
    });
    this.$bvModal.hide('modal-job-step')
  }

  public sendPerguntasCorte(b: string) {
    if(b) {
      this.candidatarse()
    }
    this.vaga!.isCandidatado = !b;
    this.$bvModal.hide('modalPerguntasCorte')
  }
  get requireFullProfile() : boolean {
    return ((this.vaga!.requireProfile && !this.candidatoService.isProfileCompletedToJob()) 
        ||
        (this.vaga!.requireVideoCurriculum && !this.candidatoService.haveVideoCurriculum()))
  }
  get hiddenCurriculumDone() : boolean {
    return (
              ((this.candidato?.perfil?.formacoes?.length||0) > 0 || this.candidato?.perfil?.temFormacao===false)
           && ((this.candidato?.perfil?.experiencias?.length||0) > 0 || this.candidato?.perfil?.temExperiencia===false)
        )
  }

  public canIApply() {
    if(!this.candidato)
      return false;

    let valid: boolean = true;
    this.candidatoService.entidade = this.candidato;
    //  (this.vaga!.requireVideoCurriculum && !this.candidatoService.haveVideoCurriculum())
    if (this.requireFullProfile)
    {
      valid = false;
      this.$alert.title = this.$t("PROCESS STEPS");
      this.$alert.addMsg(this.$t("1st Step: Complete your profile").toString());
      // this.$alert.addMsg(this.$t("2ª etapa: VÍDEO ENTREVISTA").toString());
    } else if (this.vaga?.mostrarCurriculoOculto) {      
      this.$bvModal.show('modalCurriculoOculto');
      return false;
    }
    
    if (!valid) {
      this.$alert.callBoxCheck(this.$t("Complete my profile").toString()).then(_ => { _ ? (this.$router.push({ path: '/edit', query: {lang: this.$i18n.locale, vaid: this.$route.params.id}})) : null;})
    }

    return valid;
  }

  public checkProfile() {
    if(this.canIApply()){
      this.apply()
    }
    this.$bvModal.hide('modal-job-step')
  }

  get hasHOrP()
  {
    return ((this.vaga!.PerguntasCorte && this.vaga!.PerguntasCorte!.length>0) || (this.vaga!.vagaHabilidades && this.vaga!.vagaHabilidades!.length>0));
  }

  public async apply() {
    if (this.hasHOrP && !this.isPublic && this.vaga!.isAllowed) {
      this.$bvModal.show('modalPerguntasCorte');
      if((this.$refs.mpc as ModalPerguntasCorte))
        (this.$refs.mpc as ModalPerguntasCorte).loadPerguntasCorte();
    } else {
      if(this.applicable && !this.vaga!.isCandidatado && this.vaga!.isAllowed) {
          this.candidatarse()
      } else if(this.vaga?.isCandidatado && this.vaga?.isPendente) {
        this.candidatura = await this.candidaturaService.fetchAll({idvaga: this.vaga.id});
        this.goToInterview();
      }
    }
    this.$bvModal.hide('modal-job-step')
  }

  public async candidatarse() {
    let ddLegal: boolean = true;
      if(this.vaga?.empresa?.configuracoes?.ENABLE_DOUBLE_TERMS_CHECK === true) {
        this.$confirm.addTitle(this.$t('Do you wish to proceed?').toString())
        this.$confirm.addMsg(this.$t('By applying for this job, you agree on sharing your data to the recruiting team for evaluation purposes.'))
        
        this.$confirm.msg.push(this.$createElement("br"))
        this.$confirm.addMsg(this.$t('See more')+":")
        this.$confirm.msg.push(this.$createElement("a",{attrs: {href: 'https://jobecam.com/talento-termos-de-uso-e-privacidade/', target: '_blank'}}, this.$t('Terms of Use and Privacy Policy').toString()))

        ddLegal = await this.$confirm.callBox();
      }
    if(ddLegal==true) 
      this.applyPromise = this.candidaturaService.create({ idvaga: this.vaga!.id, organica: ( this.invite ? "NAO" : "SIM" ) })
      .then((data: CandidaturaEntity) => {
        this.vaga!.isCandidatado = true;

        if (data && data.id) {
          this.candidatura = data

          if (this.candidatura.status === "VIDEOSPENDENTES") {
            this.goToInterview();
          } else if (this.candidatura.status === "REVISAOCURRICULAR") {
            this.$alert.title = this.$t("Application completed!");
            this.$alert.addMsg(this.$t("Your profile is in analysis for the recruiter").toString());
            this.$alert.callBoxCheck(this.$t("Ok").toString());
          } else if (this.candidatura.status === "EXAMESPENDENTES") {
            this.goToExam();
          }
        }
      }).catch((reason) => {
        const dt: any = reason.response.data;
        if (dt.type && dt.detail && dt.status == 400) {
          this.openAlert(dt.detail.split('<br />'));
        }
      });
  }
  
  public openAlert(msg: string[], title?: string) {
    const alert: any = new Alert();
    msg.forEach((m: string) => {
      if (m == '') {
        alert.addMsg(this.$t("Oops! An unexpected error occurred!").toString());
      } else {
        alert.addMsg(m);
      }
    });
    if (title) {
      alert.addTitle(title)
    }
    alert.callBoxError();
  }
  /**
   * diversitySaved
   */
  public diversitySaved() {
    console.log('dados de diversidade salvos com sucesso')
  }
  
  /**
   * diversityError
   */
  public diversityError() {
    console.log('dados de diversidade não coletados')
  }
}
