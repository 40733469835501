




















































































































































































































































































import Alert from '@/components/alerts/Alert.vue';
import Confirm from '@/components/alerts/Confirm.vue';
import PlayLoader from '@/components/loader/PlayLoader.vue';
import RecLoader from '@/components/loader/RecLoader.vue';
import { EVideoPurpose } from '@/core/Enums/EVideoPurpose';
import { EVideoSupplier } from '@/core/Enums/EVideoSupplier';
import OpenViduApp from '@/core/recording/OpenViduApp';
import PlatformUtil from '@/core/recording/PlatformUtil';
import RecordService from '@/core/Services/Video/Record';
import RoomService from '@/core/Services/Video/Room';
import { Event, LocalRecorderState, OpenViduError } from 'openvidu-browser';
import { OpenViduErrorName } from 'openvidu-browser/lib/OpenViduInternal/Enums/OpenViduError';
import vueAwesomeCountdown from 'vue-awesome-countdown';
import { Component, Prop, Vue } from 'vue-property-decorator';

Vue.use(vueAwesomeCountdown, "countdown");

@Component<StartRecord>({
  components: {
    PlayLoader,
    RecLoader,
  }
})
export default class StartRecord extends Vue {
  
  // <!-- #region Attributes Declaration -->  
  public room: RoomService;
  public record: RecordService;
  public roomManager!: OpenViduApp;
  public startedProcess: boolean = false;
  public testingPhase: boolean = true;
  public showPopover: boolean = true;
  public deviceError: boolean = false;
  public startingProcess: boolean = false;
  public displayingCounterOnStart: boolean = true;
  public startedRecProcess: boolean = false;
  public alteringRecProcess: boolean = false;
  public recording: boolean = false;
  public preview: boolean = false;
  public hasVideo: boolean = false;
  public blockSendTimeout: boolean = false;
  public alert!: Alert;
  public confirm!: Confirm;
  public options!: any;
  public currentPromise: any|null = null;
  public msgString: string = '';
  public beforeUnloadListener: any|null = null;
  public unloadListener: any|null = null;
  public currentVideoDeviceId: string|null = null;
  public currentAudioDeviceId: string|null = null;

  private startCounterFinished: boolean = false;
  private errorOnLocalRecorder: boolean = false;
  public time: number = 0;
  public PID: any = 0;
  public startCounterTime: number = 60000; // 60 seconds

  @Prop({ default: 30000 })
  public maxTime!: number; // default 30 seconds

  @Prop({ default: "" })
  public startMessage!: string;

  @Prop({ default: "" })
  public question!: string;

  @Prop({ default: "" })
  public questionLang!: string;

  private volumeLogEventDispatcher: any = null;

  @Prop({ default: EVideoSupplier.Openvidu })
  public supplier!: number;

  @Prop({ default: false })
  public logVolume!: boolean;

  @Prop({ default: EVideoPurpose.Curriculum })
  public purpose!: number;

  @Prop({ default: false })
  public showCounterOnStart!: boolean;
  // <!-- #endregion -->

  constructor() {
    super();
    this.room = new RoomService();
    this.roomManager = new OpenViduApp(null, 1);
    this.record = new RecordService();
    this.options = {
      container: "#page-content",
      easing: "ease-in",
      offset: -60,
      force: true,
      cancelable: true,
      x: false,
      y: true
    };
  }

  // <!-- #region Computed -->
  
  get canMakeActions(): boolean {
    return this.purpose != EVideoPurpose.TestOnly;
  }

  get isMobileComponentActive(): boolean {
    return this.$route.name == "EntrevistaMobile"
  }

  // <!-- #endregion -->
  
  /* <!-- #region Device Management --> */
  get videoDevices() {
    return this.roomManager.videoDevices;
  }
  get audioDevices() {
    return this.roomManager.audioDevices;
  }
  public toggleCamera(deviceId: string) {
    console.log('/==toggleCamera==/')
    this.roomManager.toggleCamera(deviceId);
  }

  public toggleTestCamera(deviceId: string) {
    console.log('/==toggleTestCamera==/')
    this.testingPhase = true;
    this.roomManager.currentVideoDeviceId = deviceId;
    this.roomManager.removePublisher();
    this.startProcess()
  }

  public toggleTestAudio(deviceId: string) {
    console.log('/==toggleTestAudio==/')
    this.roomManager.currentAudioDeviceId = deviceId;
  }

  public toggleDevices() {
    console.log('/==toggleDevices==/')
    this.showPopover = false;
    this.deviceError = false;
    this.testingPhase = true;
    this.roomManager.currentVideoDeviceId = this.currentVideoDeviceId||undefined;
    this.roomManager.currentAudioDeviceId = this.currentAudioDeviceId;
    this.roomManager.removePublisher();
    this.startProcess()
  }
  /* <!-- #endregion --> */
  
  // <!-- #region LifecycleHooks -->
  public beforeDestroy() {
    console.log('/==beforeDestroy==/')
    this.removeListeners();
    this.forceStopRecording();
  }

  public mounted() {
    this.alert = new Alert();
    let me: any = this;
    this.msgString = this.$t("Do you want to exit the recording page? you can lose your data").toString();
    const unloadListener = (e: any) => {
      me.forceStopRecording();
    };
    // const beforeUnloadListener = (e: any) => {
    //   e.preventDefault();
    //   e.returnValue = this.msgString;
    //   return this.msgString;
    // };
    // this.beforeUnloadListener = beforeUnloadListener;
    this.unloadListener = unloadListener;

    window.addEventListener("unload",  this.unloadListener);
    window.onpopstate = (e: any) => {
      // window.removeEventListener("beforeunload",  this.beforeUnloadListener);
      window.removeEventListener("unload",  this.unloadListener);
      me.forceStopRecording();
    };
    // window.addEventListener("beforeunload", this.beforeUnloadListener);
  }
  // <!-- #endregion -->

  // <!-- #region Listeners -->

  public removeListeners() {
    console.log('/==removeListeners==/')
    window.removeEventListener("beforeunload",  this.beforeUnloadListener);
    window.removeEventListener("unload",  this.unloadListener);
  }
  
  public onVideoElementCreated(event: Event) {
    console.log('/==onVideoElementCreated==/')
    this.hasVideo = true;
    this.startingProcess = false;
    this.startedProcess = true;

    /* console.log("started") */
    /* console.log(this.purpose) */
    this.$emit("startedProcess");
  }

  public onVideoElementDestroyed(event: Event) {
    console.log('/==onVideoElementDestroyed==/')
    this.hasVideo = false;
  }

  private emitVolumeChange(event: any): void {
    console.log('/==emitVolumeChange==/')
    this.$emit("volumeChange", event.value.newValue);
  }

  // <!-- #endregion -->

  // <!-- #region STOP -->

  public stopStartCounter() {
    console.log('/==stopStartCounter==/')
    this.displayingCounterOnStart = true;
    (<any>this.$refs.startCounter).finishCountdown();
  }
  
  public preventDoubleClickStopButton: boolean = false;
  public async stopRecording() {
    console.log('/==stopRecording==/');
    if(this.preventDoubleClickStopButton) return;
    this.preventDoubleClickStopButton = true;
    this.time = 0;
    (<any>this.$refs.counter).stopCountdown();
    if(this.PID)
      clearInterval(this.PID);
      
    this.roomManager.speech?.stop();
    if (this.roomManager.localRecorder == null ) {
      console.log('/==stopRecording - server==/');
      this.roomManager.leaveRoom();
      if (this.supplier && this.record.entidade && this.record.entidade.id) {
        await this.record.stopRecording(
          this.supplier,
          this.room.entidade!.key,
          this.record.entidade.id
        ).then(() => {
          if (this.purpose === EVideoPurpose.Curriculum) {
            this.loadLastVideo();
          }
          
          if (this.purpose === EVideoPurpose.Interview) {
            this.$toast.variant = "success";
            this.$toast.addTitle(this.$t("Success").toString());
            this.$toast.addMsg(this.$t("Answer Sended").toString());
            this.$toast.open();
          }
          this.$emit("recordEnded", this.record.entidade, this.room.entidade, this.roomManager.speech?.finalTranscript);
          this.preventDoubleClickStopButton = false;
        }).catch(() => {
          this.reloadWithErrorMessage();
        })
      }
      if (
        this.room.entidade &&
        this.room.entidade.id &&
        this.purpose == EVideoPurpose.Curriculum
      ) {
        await this.room.close(this.room.entidade.id);
      }
    } else {
      console.log('/==stopRecording - local==/',this.roomManager.localRecorder.state);
      this.roomManager.removePublisher(true);
      if(this.roomManager.localRecorder.state == LocalRecorderState.RECORDING || 
        this.roomManager.localRecorder.state == LocalRecorderState.FINISHED) {
        try {
          if(this.roomManager.localRecorder.state == LocalRecorderState.RECORDING)
            await this.roomManager.localRecorder.stop();

          if (this.purpose === EVideoPurpose.Curriculum) {
            this.loadLastVideo();
          }
          this.$emit("recordEnded", this.roomManager?.localRecorder, null, this.roomManager.speech?.finalTranscript);
          this.roomManager.closeMediaStream();
          this.preventDoubleClickStopButton = false;
        } catch(e) {
          this.reloadWithErrorMessage();
          console.log(e)
        }
      } else {
        this.reloadWithErrorMessage();
      }
    }
    this.startedRecProcess = false;
    this.alteringRecProcess = true;

  }

  public stopVolumeLog(): void {
    console.log('/==stopVolumeLog==/')
    if (this.volumeLogEventDispatcher)
      this.volumeLogEventDispatcher.off("streamAudioVolumeChange");
  }

  public forceStopRecording() {
    console.log('/==forceStopRecording==/');
    /* console.log("forceStop") */
    clearInterval(this.PID);
    if (this.startedRecProcess) {
      if(<any>this.$refs.counter)
        (<any>this.$refs.counter).stopCountdown();
      if (
        this.room.entidade &&
        this.room.entidade.id &&
        this.purpose == EVideoPurpose.Curriculum
      ) {
        this.room.close(this.room.entidade.id);
      }
      if (this.supplier && this.record.entidade && this.record.entidade.id) {
        this.$emit("forceEnded", this.room.entidade);
      }
    }
    if (this.roomManager) this.roomManager.removePublisher();
  }

  // <!-- #endregion -->

  // <!-- #region START -->

  public startProgress() {
    console.log('/==startProgress==/')
    this.preventDoubleClickStopButton = false;
    this.time = 0;
    this.PID = setInterval(() => {
      this.time = this.time + 100;
      if (this.time >= this.maxTime+1000) {
        clearInterval(this.PID);
      }
    }, 100);
    // regardless, start the recognition
    this.roomManager.speech?.start(this.questionLang);
  }

  public onStartCounterFinished() {
    console.log('/==onStartCounterFinished==/')
    this.startCounterFinished = true;
    this.blockSendTimeout = true;
    this.doStartRecording();
  }
  
  public async startRecording() {
    console.log('/==startRecording==/');
    if(this.roomManager.publisher?.videos[0].video)
      (this.roomManager.publisher?.videos[0].video as HTMLVideoElement).muted  = true;
    if (this.showCounterOnStart) {
      this.blurVideo();
      this.displayingCounterOnStart = false;
      (<any>this.$refs.startCounter).startCountdown(true);
      this.$emit("startCountDownStarted");
    } else {
      this.doStartRecording();
    }
  }

  private async doStartRecording() {
    console.log('/==doStartRecording==/');
    if(this.roomManager.localRecorder != null) {
     this.doStartLocalRecording();
    } else {
      this.doStartServerRecording();
    }
  }

  public async doStartLocalRecording() {
    console.log('/==doStartLocalRecording==/');
     // now here is where thing get interessing, if the record returns an error whe have to start the process of entering the room again
     try {
        await this.roomManager?.record()
        this.startProgress();
        this.alteringRecProcess = false;
        this.startedRecProcess = true;
        setTimeout(() => {
          this.$nextTick(() => {
            (<any>this.$refs.counter).startCountdown(true);
          });
        },1000);
        setTimeout(() => {
          this.blockSendTimeout = false;
        },10000)
        this.unBlurVideo();
     }catch(e) {
        console.error(e)
        console.log('Não foi possivel iniciar o localrecorder')
        this.errorOnLocalRecorder = true;
        this.reloadWithErrorMessage()
     }    
  }

  public async doStartServerRecording() {
    console.log('/==doStartServerRecording==/');
    try {    
    let resolutionString: string = "640x360";
    if (window.innerWidth < window.innerHeight) {
      resolutionString = "360x640";
    }
    
      let data = await this.record.startRecording({ session: this.room.entidade!.key, outputMode: "COMPOSED", resolution: resolutionString });
      if(data?.id) {
        this.startProgress();
        this.alteringRecProcess = false;
        this.startedRecProcess = true;
        setTimeout(() => {
          this.$nextTick(() => {
            (<any>this.$refs.counter).startCountdown(true);
          });
        },1000);
        setTimeout(() => {
            this.blockSendTimeout = false;
        },10000)
        this.unBlurVideo();
      } else {
        this.reloadWithErrorMessage();
      } 
    } catch(e) {
      console.log(e)
      this.reloadWithErrorMessage();
    }
  }
  
  public async startProcess() {
    console.log('/==startProcess==/')
    this.startingProcess = true;
    this.alteringRecProcess = false;

    await this.roomManager.createPublisher(
      "video-container",
      this.onAccessDenied, 
      this.onVideoElementCreated,
      this.onVideoElementDestroyed )
      .then((d) => {
        this.$emit("testingProcessStarted");
        if(this.testingPhase && this.roomManager.publisher?.videos[0].video)
          (this.roomManager.publisher?.videos[0].video as HTMLVideoElement).muted  = false;
        
      })
      .catch((e: any) => {
        this.openCameraError(e);
      });
      

    if(this.roomManager.publisher) {
      
      /* console.log('here') */

      /* if (this.purpose == EVideoPurpose.TestOnly) {
        setTimeout(() => {
          VueScrollTo.scrollTo("#comecarButton", 500, this.options);
        }, 500);
      } */

      if (this.logVolume) {
        this.initVolumeLog();
      }
    }
  }

  public initVolumeLog(): void {
    console.log('/==initVolumeLog==/')
    if (this.roomManager.publisher!.videoReference) {
      this.volumeLogEventDispatcher = this.roomManager.onVolumeChange(
        this.emitVolumeChange
      );
    } else {
      this.roomManager.publisher!.on("videoElementCreated", () => {
        this.volumeLogEventDispatcher = this.roomManager.onVolumeChange(
          this.emitVolumeChange
        );
      });
    }
  }

  //  <!-- #endregion -->

  // <!-- #region Room Handlers -->
  public closeRoom() {
    console.log('/==closeRoom==/')
    if(this.room.entidade && this.room.entidade.id)
      this.room.close(this.room.entidade.id);
  }
  // <!-- #endregion -->

  // <!-- #region Curriculum -->
  public cancelVideoCV() {
    console.log('/==cancelVideoCV==/')
    this.record.delete({ id: this.record.entidade!.id });
    this.$emit("cancel");
  }
  
  public changeVideo() {
    console.log('/==changeVideo==/')
    //video-preview-file
    if (this.purpose != EVideoPurpose.Curriculum) {
      this.$emit("changeApproved", (this.roomManager?.localRecorder??this.record.entidade));
      return true;
    }
    let me: any = this;
    this.alert.addTitle(this.$t("Attention").toString());
    this.alert.addMsg(this.$t("Want to apply this video to your profile?"));
    let obj: any = {
      b1Title: this.$t("No").toString(),
      b2Title: this.$t("Yes").toString(),
      b1CallBack: () => {
        this.cancelVideoCV();
      },
      b2CallBack: () => {
        this.$emit("changeApproved", (this.roomManager?.localRecorder??this.record.entidade));
        this.closeRoom();
      }
    };
    this.alert.callBoxCheckWithTitleAndDoubleButtons(obj);
  }

  public loadLastVideo() {
    console.log('/==loadLastVideo==/')
    this.preview = true;
    let url: string =  '';

    if(this.roomManager.localRecorder?.state == LocalRecorderState.FINISHED) {
      url = URL.createObjectURL(this.roomManager.localRecorder.getBlob());
    } else {
      url = this.record.entidade.url;
    }
    (<HTMLVideoElement>(
      document.getElementById("video-preview-file")
    )).src = url;
    (<HTMLVideoElement>document.getElementById("video-preview-file")).load();
  }

  // <!-- #endregion -->

  // <!-- #region Utils -->

  public formatTime(time: number): string {
    time = time < 0 ? 0 : time;
    const seconds = time / 1000; // divided by int return int
    const valor_time_mod = seconds < -1 ? seconds * -1 : seconds;
    const m = (Math.round(seconds - (seconds % 60)) / 60).toString();
    const s = Math.round(valor_time_mod % 60).toString();
    return (m.length == 1 ? "0" + m : m) + ":" + (s.length == 1 ? "0" + s : s);
  }
  
  public getVideoElement() {
    console.log('/==getVideoElement==/');
    return this.roomManager.publisher!.videos[0].video;
  }

  public blurVideo() {
    console.log('/==blurVideo==/');
    this.getVideoElement().classList.add("blur-video");
  }
  public unBlurVideo() {
    console.log('/==unBlurVideo==/');
    this.getVideoElement().classList.remove("blur-video");
  }
  // <!-- #endregion -->

  // <!-- #region Counter -->
  public countdownTime(time: number): string {
    console.log('/==countdownTime==/')
    return Math.round(time / 1000).toString();
  }
  // <!-- #endregion -->

  // <!-- #region Exception and Alerts -->
  
  public onAccessDenied(e: Event) {
    console.log('/==onAccessDenied==/')
    //this.startingProcess = false;
    this.$emit('openviduError', (e as unknown) as OpenViduError);
  }

  public openCameraError(e: any) {
    console.log('/==openCameraError==/')
    // TODO: check browser name and version
    // TODO: add select to device
    this.deviceError = true;
    this.showPopover = true;
    this.$alert.addMsg(this.$t('DEVICE_ALREADY_IN_USE').toString());
    this.$alert.addTitle(this.$t('Your camera and microphone are blocked!').toString());
    /* console.log(e) */
    if(e && e.name == OpenViduErrorName.DEVICE_ACCESS_DENIED) {
      this.$alert.addMsg(this.$t('Jobecam needs to have access to the camera and microphone. Click the blocked camera icon in your browsers address bar and refresh the page').toString());
    } else if(e && e.name == OpenViduErrorName.DEVICE_ALREADY_IN_USE) {
      // nothing
    } else if(e && e.name) {
      this.$alert.addMsg(this.$t('There was a problem trying to free your camera and microphone, check that they are connected correctly').toString());
    }
    this.$alert.callBoxError().then(() => {
      window.removeEventListener("beforeunload", this.beforeUnloadListener);
    });
  }
  
  public throwDeviceError(e: any) {
    console.log('/==throwDeviceError==/');
    if (e && e.name == OpenViduErrorName.DEVICE_ACCESS_DENIED) {
      this.$alert.addMsg(this.$t('Jobecam needs to have access to the camera and microphone. Click the blocked camera icon in your browsers address bar and refresh the page').toString());
    } else if (e && e.name) {
      this.$alert.addMsg(this.$t('There was a problem trying to free your camera and microphone, check that they are connected correctly').toString());
    }
    this.$alert.addMsg(this.$t('Another program may be using your camera, please close it and refresh the page').toString());
    this.$alert.addTitle(this.$t('Your camera and microphone are blocked!').toString());
    this.$alert.callBoxError();
  }

  public reloadWithErrorMessage() {
    console.log('/==reloadWithErrorMessage==/');
    this.alert.title = this.$t('Oops').toString();
    this.alert.addMsg(this.$t('Check your connection. Your recording cannot be sent, please try again!').toString())
    this.alert.callBoxError();
    if (this.roomManager) this.roomManager.removePublisher();
    if(<any>this.$refs.counter)
      (<any>this.$refs.counter).stopCountdown();
    clearInterval(this.PID);
    this.startedRecProcess = false;
    this.startingProcess = false;
    this.startedProcess = false;
    this.alteringRecProcess = false;
    this.displayingCounterOnStart = false;
    //this.unBlurVideo();
    try {
      this.room.close(this.room.entidade!.id);
    } catch(e) {
      /* console.log(e.message) */
    }
  }

  // <!-- #endregion -->

  // <!-- #region Prepare Recording -->

  public async prepareToRecord() {
    console.log('/==prepareToRecord==/');
    this.$emit('testEnded',true)
    
    if(this.testingPhase)
      this.testingPhase = false;

    if (!this.roomManager.publisher) {
      this.startProcess();
    }

    this.preview = false;
    this.alteringRecProcess = true;


    if (this.purpose == EVideoPurpose.Interview) {
      // VueScrollTo.scrollTo("#questionTabs", 500, this.options);
    }

    let c: boolean = false;
    
    if ((!this.$route.query.fs || this.$route.query.fs != "1") && !PlatformUtil.getInstance().isIPhoneOrIPad())
      c = await this.createLocalRedord();

    if(!c || this.errorOnLocalRecorder) {
      this.roomManager.localRecorder = null;
      await this.createRoomForServerRecord()
    } 
  }

  public async createLocalRedord(): Promise<boolean> {
    console.log('/==createLocalRedord==/');
    return new Promise(async (resolve: any, reject: any): Promise<void> => {
      try {         
        await this.roomManager.createLocalRecorder();
        resolve(true);
        this.startRecording();
      }catch(e: any) {
        this.roomManager.localRecorder = null;
        console.warn(e?.message)
        resolve(false);
      }
    })
  }
  

  public async createRoomForServerRecord() {
    console.log('/==createRoomForServerRecord==/');
    let roomData: any;
    
    // TODO: create local recorder
    // first we need to check if the publisher is set and we have the stream
    // then we need to create a local recorder with 
    // https://docs.openvidu.io/en/stable/api/openvidu-browser/classes/openvidu.html#initlocalrecorder
    if (this.purpose === EVideoPurpose.Curriculum) {
      roomData = await this.room.createCurriculum(EVideoSupplier.Openvidu);
    } else {
      roomData = await this.room.createInterview(EVideoSupplier.Openvidu);
    }
    // let roomData = await this.room.createCurriculum(EVideoSupplier.Openvidu);

    if (roomData && roomData.id) {
      /* console.log(roomData) */
      this.room.entidade = roomData;
      this.roomManager.sessionId = roomData.key;
      try {
        await this.roomManager.joinRoom();
        this.startRecording();
      }catch(e: any) {
        if(e.response?.data?.type?.indexOf("custom_msg") > -1) {
          // alterar essa msg
          return;
        }
        /* console.log(e) */
        this.throwDeviceError(e);
      }     
    } else {
      this.alert.addMsg("" + this.$t("Please try again later"));
      this.alert.title = this.$t("Your Video cannot be uploaded.");
      this.alert.callBoxError();
    }
  }

  //  <!-- #endregion -->

}
